/** @format */

import React from "react";

import { Helmet } from "react-helmet";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

import { removeItem } from "../library/localStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 80px)",
    padding: "4px",
    maxWidth: "1440px",
    marginLeft: "auto",
    marginRight: "auto",
    alignContent: "flex-start",
  },
}));

const Account = ({ user, match }) => {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const { name = "", email = "", avatar = "", phoneNumber = "" } = user || {};

  return (
    <>
      <Helmet>
        <title>Account Disabled</title>
      </Helmet>

      <Grid container className={classes.root}>
        <Grid item xs={12} style={{ paddingTop: "4px" }}>
          <Card style={{ borderRadius: "8px" }}>
            <CardHeader title={name} subheader={phoneNumber} />
            <CardContent>
              <Typography>
                Your account is not active, please contact admin.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  removeItem("token");
                  window.location.href = "/";
                }}
              >
                Sign out
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Account;
