/** @format */

import { gql } from "apollo-boost";

export const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      id
      uid

      isActive

      name
      avatar
      phoneNumber

      permissions
    }
  }
`;

export const USER_COUNT = gql`
  query USER_COUNT($where: UserCountWhereInput!) {
    userCount(where: $where)
  }
`;

export const USER = gql`
  query USER($where: UserWhereInput!) {
    user(where: $where) {
      id
      uid

      isActive

      name
      username
      phoneNumber
      email

      isProfile

      dateOfBirth
      dateOfDeath

      permissions
    }
  }
`;

export const USERS = gql`
  query USERS($where: UsersWhereInput!) {
    users(where: $where) {
      id
      uid

      isActive

      name
      username
      phoneNumber
      email

      isProfile

      dateOfBirth
      dateOfDeath

      permissions
    }
  }
`;
