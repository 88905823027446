/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
// import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      cursor: "pointer",
    },
    grow: {
      display: "flex",
      flex: 1,
    },
  };
});

const AppHeader = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar position="static">
      <Toolbar>
        {/* 
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton> 
        */}
        <Typography
          variant="h6"
          className={classes.title}
          onClick={() => {
            history.push("/");
          }}
        >
          History
        </Typography>

        {/* 
        <Button
          color="inherit"
          onClick={() => {
            history.push("/deities");
          }}
          style={{
            marginLeft: "20px",
          }}
        >
          Deities
        </Button>
         */}

        <div className={classes.grow} />

        {!user && (
          <Button
            color="inherit"
            onClick={() => {
              history.push("/sign-in-mobile");
            }}
          >
            Sign In
          </Button>
        )}
        {user && (
          <IconButton
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={() => {
              history.push("/account");
            }}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
