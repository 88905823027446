/** @format */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useHistory, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

// import Grid from "@material-ui/core/Grid";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
// import MapIcon from "@material-ui/icons/Map";
// import PlaceIcon from "@material-ui/icons/Place";

// import TempleIcon from "./icons/TempleIcon";
import TimelineIcon from "../../icons/Timeline";

const useStyles = makeStyles((theme) => ({
  bottomNav: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
  },
  lable: {
    // fontSize: "10px",
  },
  selected: {
    // fontSize: "10px",
  },
  wrapper: {
    // fontSize: "10px",
  },
}));

const AppFooter = ({ user, children }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [value, setValue] = useState("/");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(newValue);
  };

  useEffect(() => {
    setValue(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      className={classes.bottomNav}
    >
      <BottomNavigationAction
        classes={{ label: classes.lable, selected: classes.selected }}
        label="Home"
        value="/"
        icon={<HomeIcon />}
      />

      <BottomNavigationAction
        classes={{ label: classes.lable, selected: classes.selected }}
        label="Search"
        value="/search"
        icon={<SearchIcon />}
      />

      <BottomNavigationAction
        classes={{ label: classes.lable, selected: classes.selected }}
        label="Timeline"
        value="/timeline"
        icon={<TimelineIcon isActive={value === "/timeline"} />}
      />

      <BottomNavigationAction
        classes={{ label: classes.lable, selected: classes.selected }}
        label="Groups"
        value="/groups"
        icon={<PeopleIcon />}
      />

      <BottomNavigationAction
        classes={{ label: classes.lable, selected: classes.selected }}
        label="Profiles"
        value="/profiles"
        icon={<PersonIcon />}
      />
    </BottomNavigation>
  );
};

AppFooter.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

AppFooter.defaultProps = {
  user: null,
};

export default AppFooter;
