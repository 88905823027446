/** @format */

import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  svg: {
    width: "20px",
    height: "20px",
  },
}));

const TimelineIcon = ({ isActive }) => {
  const classes = useStyles();

  const fill = isActive ? "#3f51b5" : "rgba(0, 0, 0, 0.54)";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 950 950"
      className={classes.svg}
    >
      <path
        style={{ fill }}
        d="M576.23,475c0-47.24-33.23-86.8-77.87-97.21V197.13c44.64-10.41,77.87-50,77.87-97.21C576.23,44.74,530.91,0,475,0S373.77,44.74,373.77,99.92c0,47.24,33.23,86.8,77.87,97.21V377.79c-44.64,10.41-77.87,50-77.87,97.21s33.23,86.8,77.87,97.21V752.87c-44.64,10.41-77.87,50-77.87,97.21C373.77,905.26,419.09,950,475,950s101.23-44.74,101.23-99.92c0-47.24-33.23-86.8-77.87-97.21V572.21C543,561.8,576.23,522.24,576.23,475Z"
      />
      <rect
        style={{ fill }}
        x="654.1"
        y="127.59"
        width="171.31"
        height="49.19"
        rx="6.32"
      />
      <rect
        style={{ fill }}
        x="654.1"
        y="23.06"
        width="295.9"
        height="49.19"
        rx="6.32"
      />
      <rect
        style={{ fill }}
        x="124.59"
        y="502.63"
        width="171.31"
        height="49.19"
        rx="6.32"
      />
      <rect
        style={{ fill }}
        y="397.99"
        width="295.9"
        height="49.19"
        rx="6.32"
      />
      <rect
        style={{ fill }}
        x="654.1"
        y="877.75"
        width="171.31"
        height="49.19"
        rx="6.32"
      />
      <rect
        style={{ fill }}
        x="654.1"
        y="773.22"
        width="295.9"
        height="49.19"
        rx="6.32"
      />
    </svg>
  );
};

TimelineIcon.propTypes = {
  isActive: PropTypes.bool,
};

TimelineIcon.defaultProps = {
  isActive: false,
};

export default TimelineIcon;
