/** @format */

export function setItem(key, value) {
  if (typeof Storage !== "undefined") {
    localStorage.setItem(key, value);
    return true;
  }
  throw new Error("Local Storage not available.");
}

export function getItem(key) {
  if (typeof Storage !== "undefined") {
    return localStorage.getItem(key);
  }
  throw new Error("Local Storage not available.");
}

export function removeItem(key) {
  if (typeof Storage !== "undefined") {
    return localStorage.removeItem(key);
  }
  throw new Error("Local Storage not available.");
}
