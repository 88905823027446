/** @format */

import { gql } from "apollo-boost";

export const SIGN_IN = gql`
  mutation SIGN_IN($data: SignInInput!) {
    signin(data: $data) {
      id
      name
      email
      permissions

      token
    }
  }
`;

export const SIGN_IN_PHONE = gql`
  mutation SIGN_IN_PHONE($data: SignInPhoneInput!) {
    signInPhone(data: $data) {
      id
      uid

      isActive

      name
      avatar
      phoneNumber

      permissions

      token
    }
  }
`;

export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signout {
      message
    }
  }
`;

export const SIGN_UP = gql`
  mutation SIGNUP($data: SignUpInput!) {
    signup(data: $data) {
      id
      name
      email
      permissions

      token
    }
  }
`;

export const CREATE_USER = gql`
  mutation CREATE_USER($data: UserCreateInput!) {
    createUser(data: $data) {
      id
      name
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER($data: UserUpdateInput!) {
    updateUser(data: $data) {
      id
      name
    }
  }
`;

export const DELETE_USER = gql`
  mutation DELETE_USER($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;
