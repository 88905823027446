/** @format */

import React from "react";
import PropTypes from "prop-types";

// import { useHistory, useLocation } from "react-router-dom";
// import { makeStyles } from "@material-ui/core/styles";

import AppHeader from "./containers/AppHeader";
import AppFooter from "./components/AppFooter";

// const useStyles = makeStyles((theme) => ({
// }));

const AppLayout = ({ user, children }) => {
  // const classes = useStyles();
  // const history = useHistory();
  // const location = useLocation();

  return (
    <>
      <AppHeader user={user} />
      {children}
      <AppFooter />
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.element.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

AppLayout.defaultProps = {
  user: null,
};

export default AppLayout;
