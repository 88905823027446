/** @format */

import ApolloClient from "apollo-boost";

import { getItem } from "../library/localStorage";

const { REACT_APP_URI } = process.env;

const URI = `${REACT_APP_URI}/graphql`;

const client = new ApolloClient({
  uri: URI,
  headers: {
    authorization: getItem("token"),
  },
});

export default client;
