/** @format */

import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

// eslint-disable-next-line import/named
import { PrivateRoutes, PublicRoutes } from "./routes";

import { useCurrentUser } from "./hooks/users";

import AppLayout from "./AppLayout";
import AccountDisabled from "./pages/AccountDisabled";

const App = () => {
  const [currentUserFn, { data, loading, called }] = useCurrentUser();

  const { currentUser } = data || {};

  useEffect(() => {
    currentUserFn();
    // eslint-disable-next-line
  }, []);

  if (loading || !called) {
    return <CircularProgress />;
  }

  if (!currentUser) {
    return (
      <AppLayout>
        <PublicRoutes />
      </AppLayout>
    );
  }

  const { isActive } = currentUser;

  if (!isActive) {
    return (
      <AppLayout user={currentUser}>
        <AccountDisabled user={currentUser} />
      </AppLayout>
    );
  }

  return (
    <AppLayout user={currentUser}>
      <PrivateRoutes user={currentUser} />
    </AppLayout>
  );
};

export default App;
