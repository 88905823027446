/** @format */

import React, { lazy } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import * as LIST from "./list";

const Admin = lazy(() => import("../pages/Admin"));
const Timeline = lazy(() => import("../pages/Timeline"));
const Account = lazy(() => import("../pages/Account"));
const Search = lazy(() => import("../pages/Search"));
const Settings = lazy(() => import("../pages/Settings"));
const Groups = lazy(() => import("../pages/Groups"));

const Article = lazy(() => import("../pages/Article"));
const ArticleEdit = lazy(() => import("../pages/ArticleEdit"));
const ArticleNew = lazy(() => import("../pages/ArticleNew"));
const Articles = lazy(() => import("../pages/Articles"));

const Circle = lazy(() => import("../pages/Circle"));
const CircleEdit = lazy(() => import("../pages/CircleEdit"));
const CircleNew = lazy(() => import("../pages/CircleNew"));
const Circles = lazy(() => import("../pages/Circles"));

const Event = lazy(() => import("../pages/Event"));
const EventEdit = lazy(() => import("../pages/EventEdit"));
const EventNew = lazy(() => import("../pages/EventNew"));
const Events = lazy(() => import("../pages/Events"));

const Home = lazy(() => import("../pages/Home"));

const SignIn = lazy(() => import("../pages/SignIn"));
const SignInMobile = lazy(() => import("../pages/SignInMobile"));
const SignUp = lazy(() => import("../pages/SignUp"));

const User = lazy(() => import("../pages/User"));
const UserEdit = lazy(() => import("../pages/UserEdit"));
const UserNew = lazy(() => import("../pages/UserNew"));
const Users = lazy(() => import("../pages/Users"));

const Profile = lazy(() => import("../pages/Profile"));
const ProfileNew = lazy(() => import("../pages/ProfileNew"));
const ProfileEdit = lazy(() => import("../pages/ProfileEdit"));
const Profiles = lazy(() => import("../pages/Profiles"));

export const PublicRoutes = () => {
  const location = useLocation();

  return (
    <Switch>
      <Route exact path={LIST.HOME} component={Home} />

      <Route exact path={LIST.TIMELINE} component={Timeline} />
      <Route exact path={LIST.SEARCH} component={Search} />
      <Route exact path={LIST.GROUPS} component={Groups} />

      <Route exact path={LIST.SIGN_IN} component={SignIn} />
      <Route exact path={LIST.SIGN_IN_MOBILE} component={SignInMobile} />
      <Route exact path={LIST.SIGN_UP} component={SignUp} />

      <Route exact path={LIST.ARTICLE} component={Articles} />
      <Route exact path={LIST.ARTICLES} component={Article} />

      <Route exact path={LIST.EVENT} component={Event} />
      <Route exact path={LIST.EVENTS} component={Events} />

      <Route exact path={LIST.USER} component={User} />
      <Route exact path={LIST.USERS} component={Users} />

      <Route exact path={LIST.PROFILE} component={Profile} />
      <Route exact path={LIST.PROFILES} component={Profiles} />

      <Route exact path={LIST.CIRCLE} component={Circle} />
      <Route exact path={LIST.CIRCLES} component={Circles} />

      <Route
        exact
        path="*"
        render={() => (
          <Redirect to={{ pathname: LIST.HOME, state: { from: location } }} />
        )}
      />
    </Switch>
  );
};

export const PrivateRoutes = ({ user }) => (
  <Switch>
    <Route
      exact
      path={LIST.ADMIN}
      component={(p) => <Admin {...{ user, ...p }} />}
    />

    <Route
      exact
      path={LIST.HOME}
      component={(p) => <Home {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.TIMELINE}
      component={(p) => <Timeline {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.ACCOUNT}
      component={(p) => <Account {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.SEARCH}
      component={(p) => <Search {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.SETTINGS}
      component={(p) => <Settings {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.GROUPS}
      component={(p) => <Groups {...{ user, ...p }} />}
    />

    <Route
      exact
      path={LIST.ARTICLE_NEW}
      component={(p) => <ArticleNew {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.ARTICLE}
      component={(p) => <Article {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.ARTICLE_EDIT}
      component={(p) => <ArticleEdit {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.ARTICLES}
      component={(p) => <Articles {...{ user, ...p }} />}
    />

    <Route
      exact
      path={LIST.CIRCLES}
      component={(p) => <Circles {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.CIRCLE_NEW}
      component={(p) => <CircleNew {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.CIRCLE}
      component={(p) => <Circle {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.CIRCLE_EDIT}
      component={(p) => <CircleEdit {...{ user, ...p }} />}
    />

    <Route
      exact
      path={LIST.EVENTS}
      component={(p) => <Events {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.EVENT_NEW}
      component={(p) => <EventNew {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.EVENT}
      component={(p) => <Event {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.EVENT_EDIT}
      component={(p) => <EventEdit {...{ user, ...p }} />}
    />

    <Route
      exact
      path={LIST.USERS}
      component={(p) => <Users {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.USER_NEW}
      component={(p) => <UserNew {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.USER}
      component={(p) => <User {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.USER_EDIT}
      component={(p) => <UserEdit {...{ user, ...p }} />}
    />

    <Route
      exact
      path={LIST.PROFILES}
      component={(p) => <Profiles {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.PROFILE_NEW}
      component={(p) => <ProfileNew {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.PROFILE_EDIT}
      component={(p) => <ProfileEdit {...{ user, ...p }} />}
    />
    <Route
      exact
      path={LIST.PROFILE}
      component={(p) => <Profile {...{ user, ...p }} />}
    />

    <Route
      exact
      path="*"
      render={() => <Redirect to={{ pathname: LIST.HOME }} />}
    />
  </Switch>
);

PrivateRoutes.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};
