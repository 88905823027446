/** @format */

// import React from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";

import {
  CURRENT_USER,
  USERS,
  USER,
  USER_COUNT,
} from "../graphql/queries/users";
import {
  CREATE_USER,
  SIGN_UP,
  SIGN_IN,
  SIGN_IN_PHONE,
  UPDATE_USER,
  SIGN_OUT,
} from "../graphql/mutations/users";

export function useUserCount({ where }) {
  return useQuery(USER_COUNT, {
    variables: {
      where,
    },
  });
}

/**
 *
 * @param {Object} param
 * @param {Object} param.data
 * @param {Function} param.onCompleted
 * @param {Function} param.onError
 */
export function useSignUp({ data, onCompleted, onError }) {
  return useMutation(SIGN_UP, {
    variables: {
      data,
    },
    refetchQueries: [
      {
        query: CURRENT_USER,
      },
    ],
    onCompleted,
    onError,
  });
}

/**
 *
 * @param {Object} param
 * @param {Object} param.data
 * @param {Function} param.onCompleted
 * @param {Function} param.onError
 */
export function useCreateUser({ data, onCompleted, onError }) {
  return useMutation(CREATE_USER, {
    variables: {
      data,
    },
    onCompleted,
    onError,
  });
}

/**
 *
 * @param {Object} param
 * @param {Object} param.data
 * @param {Function} param.onCompleted
 * @param {Function} param.onError
 */
export function useSignIn({ data, onCompleted, onError }) {
  return useMutation(SIGN_IN, {
    variables: {
      data,
    },
    refetchQueries: [
      {
        query: CURRENT_USER,
      },
    ],
    onCompleted,
    onError,
  });
}

export function useSignInPhone({ data, onCompleted, onError }) {
  return useMutation(SIGN_IN_PHONE, {
    variables: {
      data,
    },
    onCompleted,
    onError,
  });
}

export function useSignOut() {
  return useMutation(SIGN_OUT, {
    refetchQueries: [
      {
        query: CURRENT_USER,
      },
    ],
  });
}

export function useCurrentUser() {
  return useLazyQuery(CURRENT_USER);
}

export function useUsers({ where, onCompleted, onError }) {
  return useQuery(USERS, {
    variables: {
      where,
    },
    onCompleted,
    onError,
  });
}

export function useUser({ where, onCompleted, onError }) {
  return useQuery(USER, {
    variables: {
      where,
    },
    onCompleted,
    onError,
  });
}

export function useUpdateUser({ data, onCompleted, onError }) {
  return useMutation(UPDATE_USER, {
    variables: {
      data,
    },
    onCompleted,
    onError,
  });
}
