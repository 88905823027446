/** @format */

// Group of people or users are called "circle"

export const ADMIN = "/admin";

export const ARTICLE = "/articles/:articleId";
export const ARTICLE_EDIT = "/articles/:articleId/edit";
export const ARTICLE_NEW = "/articles/new";
export const ARTICLES = "/articles";

export const CIRCLE = "/circles/:circleId";
export const CIRCLE_EDIT = "/circles/:circleId/edit";
export const CIRCLE_NEW = "/circles/new";
export const CIRCLES = "/circles";

export const EVENT = "/events/:eventId";
export const EVENT_EDIT = "/events/:eventId/edit";
export const EVENT_NEW = "/events/new";
export const EVENTS = "/events";

export const HOME = "/";

export const ACCOUNT = "/account";
export const TIMELINE = "/timeline";
export const SEARCH = "/search";
export const SETTINGS = "/settings";
export const GROUPS = "/groups";

export const SIGN_IN = "/signin";
export const SIGN_IN_MOBILE = "/sign-in-mobile";
export const SIGN_UP = "/signup";

export const USER = "/users/:userId";
export const USER_EDIT = "/users/:userId/edit";
export const USER_NEW = "/users/new";
export const USERS = "/users";

export const PROFILES = "/profiles";
export const PROFILE_NEW = "/profiles/new";
export const PROFILE = "/profiles/:profileId";
export const PROFILE_EDIT = "/profiles/:profileId/edit";
